// JsFromRoutes CacheKey eb43813f3c34d18724ffd3822da61590
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from "@js-from-routes/client";

export default {
  duplicate: /* #__PURE__ */ definePathHelper(
    "post",
    "/admin/v2/rewards/:id/duplicate",
  ),
  toggleTag: /* #__PURE__ */ definePathHelper(
    "post",
    "/admin/v2/rewards/:id/toggle_tag",
  ),
  createNftCollection: /* #__PURE__ */ definePathHelper(
    "post",
    "/admin/v2/rewards/:id/create_nft_collection",
  ),
  fetchPossibleWinnersToSync: /* #__PURE__ */ definePathHelper(
    "post",
    "/admin/v2/rewards/:id/fetch_possible_winners_to_sync",
  ),
  syncWinners: /* #__PURE__ */ definePathHelper(
    "post",
    "/admin/v2/rewards/:id/sync_winners",
  ),
  index: /* #__PURE__ */ definePathHelper(
    "get",
    "/admin/v2/programs/:program_id/rewards",
  ),
  create: /* #__PURE__ */ definePathHelper(
    "post",
    "/admin/v2/programs/:program_id/rewards",
  ),
  new: /* #__PURE__ */ definePathHelper(
    "get",
    "/admin/v2/programs/:program_id/rewards/new",
  ),
  edit: /* #__PURE__ */ definePathHelper("get", "/admin/v2/rewards/:id/edit"),
  show: /* #__PURE__ */ definePathHelper("get", "/admin/v2/rewards/:id"),
  update: /* #__PURE__ */ definePathHelper("patch", "/admin/v2/rewards/:id"),
  destroy: /* #__PURE__ */ definePathHelper("delete", "/admin/v2/rewards/:id"),
};
