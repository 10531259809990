import {
  Button,
  capitalize,
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  SWRDataTable,
} from "@bleu.builders/ui";
import Link from "@components/Link";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@components/ui/Accordion";
import { useFlipper } from "@contexts/FlipperContext";
import Autoplay from "embla-carousel-autoplay";
import { UseEmblaCarouselType } from "embla-carousel-react";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { useLoaderData } from "react-router-dom";

import DonationBanner from "../DonationBanner";
import MembershipBanner from "../MembershipBanner";
import StripeReceiptInfo from "../StripeReceiptInfo";
import { BackgroundHero } from "./BackgroundHero";
import { ChallengeSection } from "./ChallengeSection";
import { RewardSection } from "./RewardSection";
import SocialFeedBanner from "./SocialFeedBanner";

interface CarouselItem {
  id: string;
  title: string;
  description: string;
  link?: string;
  button_text?: string;
  image: {
    public_url: string;
  };
}

interface LoaderData {
  carousel: CarouselItem[];
}

export function LoggedPage() {
  const flipper = useFlipper();
  const { social_network_posts, donation_challenge, offers_reward } = flipper;
  return (
    <div className="flex flex-col w-full program-bg">
      <LoggedHero />
      {social_network_posts === true && <SocialFeedBanner />}
      <ChallengeSection />
      {donation_challenge === true && <DonationBanner />}
      {offers_reward === true && <MembershipBanner />}
      <RewardSection />
      <ParticipantActions />
    </div>
  );
}

function LoggedHero() {
  //@ts-ignore
  const { carousel } = useLoaderData() as LoaderData;

  return (
    <div className="relative size-full">
      <StripeReceiptInfo />
      {carousel.length > 0 ? (
        <ProgramCarousel carousel={carousel} />
      ) : (
        <BackgroundHero />
      )}
    </div>
  );
}

interface ProgramCarouselProps {
  carousel: CarouselItem[];
}

const slideUpVariants = {
  initial: {
    opacity: 0,
    y: 50,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      ease: "easeOut",
    },
  },
  exit: {
    opacity: 0,
    y: -50,
    transition: {
      duration: 0.3,
      ease: "easeIn",
    },
  },
};

function ProgramCarousel({ carousel }: ProgramCarouselProps) {
  const plugin = React.useRef(
    Autoplay({ delay: 7000, stopOnInteraction: true, playOnInit: true }),
  );

  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [api, setApi] = React.useState<
    NonNullable<UseEmblaCarouselType[1]> | undefined
  >(undefined);

  React.useEffect(() => {
    if (!api) return;

    api.on("select", () => {
      setCurrentIndex(api.selectedScrollSnap());
    });
  }, [api]);

  return (
    <Carousel
      plugins={[plugin.current]}
      className="min-h-96 md:min-h-[500px] overflow-hidden"
      opts={{ align: "start", loop: true }}
      setApi={setApi}
    >
      <CarouselContent className="absolute inset-0 ml-0">
        {carousel.map((item, index) => (
          <CarouselItem
            key={item.id || index}
            className="relative bg-cover bg-center bg-no-repeat"
            style={{ backgroundImage: `url(${item.image.public_url})` }}
          >
            <div className="absolute inset-0 flex items-center justify-center w-full bg-black/60">
              <AnimatePresence mode="wait">
                {currentIndex === index && (
                  <motion.div
                    className="size-full text-white flex flex-col justify-center items-center gap-y-2 md:gap-y-8 px-10 md:px-24 xl:px-72"
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    variants={slideUpVariants}
                    key={`content-${item.id || index}`}
                  >
                    <motion.span
                      variants={slideUpVariants}
                      className="font-bold md:text-3xl xl:text-5xl text-center"
                    >
                      {item.title}
                    </motion.span>

                    <motion.span
                      variants={slideUpVariants}
                      className="text-sm md:text-lg xl:text-2xl text-center"
                    >
                      {item.description}
                    </motion.span>

                    {item.link && (
                      <motion.div variants={slideUpVariants}>
                        <Link
                          to={item.link}
                          target={
                            isExternalLink(item.link) ? "_blank" : undefined
                          }
                        >
                          <Button className="bg-perk-primary text-perk-primary-content hover:bg-perk-primary/90 rounded-perk-button text-sm md:text-base md:py-3 md:px-6 focus:outline-none">
                            {capitalize(item.button_text) || "Learn More"}
                          </Button>
                        </Link>
                      </motion.div>
                    )}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </CarouselItem>
        ))}
      </CarouselContent>
      <CarouselNext className="right-1 md:right-4" />
      <CarouselPrevious className="left-1 md:left-4" />
    </Carousel>
  );
}

function ParticipantActions() {
  return (
    <div className="px-4 md:px-28 mb-4">
      <div className="bg-background rounded-perk-tiles px-10 border">
        <Accordion type="single" collapsible className="w-full">
          <AccordionItem value="item-1">
            <AccordionTrigger className="hover:no-underline data-[state=open]:py-10">
              Completed Activities
            </AccordionTrigger>
            <AccordionContent>
              <SWRDataTable
                searchKey="title"
                fetchPath={`/public/participant_actions`}
              />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  );
}

const isExternalLink = (url: string): boolean => {
  if (!url) return false;
  if (!url.startsWith("http")) return false;

  try {
    const currentDomain = window.location.hostname;
    const linkDomain = new URL(url).hostname;
    return currentDomain !== linkDomain;
  } catch {
    return false;
  }
};
