import { ChallegeIcon } from "@assets/end-user/ChallengeIcon";
import { Button, capitalize } from "@bleu.builders/ui";
import Link from "@components/Link";
import { useParticipant } from "@contexts/ParticipantContext";
import { useProgram } from "@contexts/ProgramContext";
import { useReferralCode } from "@hooks/useReferralCode";
import { ClockIcon } from "@radix-ui/react-icons";
import { distanceOfTimeInWords } from "@utils/formatDate";
import React from "react";
import { useLoaderData } from "react-router-dom";

import { Reward } from "../types";

export function RewardSection() {
  return (
    <div className="px-4 md:px-28">
      <CurrentRewardsList />
      <MyRewardsList />
    </div>
  );
}

function CurrentRewardsHeader() {
  const program = useProgram();
  const currentRewardsIcon = program?.current_rewards_icon?.public_url;

  return (
    <div className="flex justify-center my-5 xl:my-10 gap-x-4 md:gap-x-12 items-center text-perk-primary-content px-4">
      <div>
        {currentRewardsIcon ? (
          <img
            src={currentRewardsIcon}
            alt="Earn points Icon"
            className="max-h-24"
          />
        ) : (
          <ChallegeIcon className="current-rewards-icon" />
        )}
      </div>
      <div className="flex flex-col justify-start">
        <p className="text-2xl md:text-3xl current-rewards">
          Current Rewards and Achievements
        </p>
        <p className="md:text-xl current-rewards">
          It's{" "}
          <span className="text-perk-primary font-semibold">rewarding</span> to
          stay connected
        </p>
      </div>
    </div>
  );
}

function MyRewardsHeader() {
  const program = useProgram();
  const myRewardsIcon = program?.my_rewards_icon?.public_url;

  return (
    <div className="flex justify-center my-5 xl:my-10  gap-x-4 md:gap-x-12 items-center text-perk-primary-content px-4">
      <div>
        {myRewardsIcon ? (
          <img
            src={myRewardsIcon}
            alt="Earn points Icon"
            className="max-h-24"
          />
        ) : (
          <ChallegeIcon className="my-rewards-icon" />
        )}
      </div>
      <div className="flex flex-col justify-start">
        <p className="text-2xl md:text-3xl my-rewards">
          My Rewards and Achievements
        </p>
        <p className="md:text-xl my-rewards">
          All of the{" "}
          <span className="text-perk-primary font-semibold">
            Rewards you have earned
          </span>{" "}
        </p>
      </div>
    </div>
  );
}

const CurrentRewardsList = () => {
  const participant = useParticipant();
  const { currentRewards } = useLoaderData() as { currentRewards: Reward[] };

  const filteredRewards = currentRewards.filter(
    (reward) => !(reward.feature === false && !participant),
  );

  if (filteredRewards.length === 0) {
    return null;
  }

  const columns = filteredRewards.reduce(
    (acc, reward, index) => {
      const columnIndex = index % 3;
      acc[columnIndex].push(reward);
      return acc;
    },
    [[], [], []],
  );

  return (
    <>
      <CurrentRewardsHeader />
      <div className="hidden md:grid md:grid-cols-3 gap-x-8 mb-8">
        {columns.map((columnRewards, columnIndex) => (
          <div
            key={columnIndex}
            className={`flex flex-col gap-y-8 ${
              columnIndex === 0
                ? "items-start"
                : columnIndex === 1
                  ? "items-center"
                  : "items-end"
            }`}
          >
            {columnRewards.map((reward) => (
              <RewardCard key={reward.id} reward={reward} />
            ))}
          </div>
        ))}
      </div>

      <div className="grid grid-cols-1 gap-8 mb-8 md:hidden">
        {currentRewards.map((reward) => (
          <RewardCard key={reward.id} reward={reward} />
        ))}
      </div>
    </>
  );
};

const MyRewardsList = () => {
  const { claimedRewards } = useLoaderData() as { claimedRewards: Reward[] };

  if (claimedRewards.length === 0) {
    return null;
  }

  const columns = claimedRewards.reduce(
    (acc, reward, index) => {
      const columnIndex = index % 3;
      acc[columnIndex].push(reward);
      return acc;
    },
    [[], [], []],
  );

  return (
    <>
      <MyRewardsHeader />
      <div className="hidden md:grid md:grid-cols-3 gap-x-8 mb-8">
        {columns.map((columnRewards, columnIndex) => (
          <div
            key={columnIndex}
            className={`flex flex-col gap-y-8 ${
              columnIndex === 0
                ? "items-start"
                : columnIndex === 1
                  ? "items-center"
                  : "items-end"
            }`}
          >
            {columnRewards.map((reward) => (
              <RewardCard key={reward.id} reward={reward} />
            ))}
          </div>
        ))}
      </div>

      <div className="grid grid-cols-1 gap-8 mb-8 md:hidden">
        {claimedRewards.map((reward) => (
          <RewardCard key={reward.id} reward={reward} />
        ))}
      </div>
    </>
  );
};

function RewardCard({ reward }: { reward: Reward }) {
  const program = useProgram();
  const referralCode = useReferralCode();
  const participant = useParticipant();

  const myRewardsIcon = program?.my_rewards_icon?.public_url;
  const rewardImage = reward?.image?.public_url;

  function redirectUrl(participant) {
    if (!participant) {
      if (referralCode !== "") {
        return `/signin${referralCode}&redirect_to=/reward/${reward.id}`;
      } else {
        return `/signin?redirect_to=/reward/${reward.id}`;
      }
    } else {
      return `/reward/${reward.id}`;
    }
  }
  return (
    <div className="flex justify-center">
      <div className="w-full md:w-80 rounded-perk-tiles overflow-hidden flex gap-y-4 flex-col p-6 bg-perk-reward-tile-bg/50 border reward-tile hover:shadow-lg">
        <div className="flex justify-between items-center">
          <span className="text-xl font-bold text-perk-primary uppercase">
            {titleForReward(reward?.selection)}
          </span>
          {myRewardsIcon ? (
            <img src={myRewardsIcon} alt="Reward Icon" className="size-8" />
          ) : (
            <ChallegeIcon className="size-8 text-perk-primary reward-card-icon" />
          )}
        </div>
        <div>{rewardImage && <img src={rewardImage} alt="Reward Image" />}</div>

        <span className="text-2xl block text-perk-primary-content reward-text">
          {reward?.name}
        </span>

        <div className="flex justify-between items-center">
          <Link to={redirectUrl(participant)}>
            <Button className="bg-perk-primary text-perk-primary-content hover:bg-perk-primary/90 rounded-perk-button py-3 px-6 focus:outline-none">
              Details
            </Button>
          </Link>
          <div className="text-xs md:text-base flex justify-end items-center gap-x-1 text-perk-primary-content">
            <ClockIcon className="size-4" />
            <p className="m-0">
              {distanceOfTimeInWords(new Date(), reward.finish)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export function titleForReward(rewardType: string): string {
  switch (rewardType) {
    case "redeem":
      return "Cash-In";
    case "sweepstake":
      return "Sweepstakes";
    default:
      return rewardType;
  }
}
