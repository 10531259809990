import { Button } from "@bleu.builders/ui";
import Link from "@components/Link";
import { useReferralCode } from "@hooks/useReferralCode";
import React from "react";

import { BackgroundHero } from "./BackgroundHero";
import { ChallengeSection } from "./ChallengeSection";
import { RewardSection } from "./RewardSection";

export function NotLoggedPage() {
  return (
    <div className="flex flex-col w-full program-bg">
      <BackgroundHero />
      <ChallengeSection />
      <RewardSection />
      <SigninBanner />
    </div>
  );
}

function SigninBanner() {
  const referralCode = useReferralCode();

  return (
    <div className="bg-gray-800 text-white flex justify-between p-4 xl:py-6 xl:px-64">
      <div className="flex flex-col">
        <span className="text-xl font-semibold">
          The sooner you join, the better your chances!
        </span>
        <div>
          <span className="font-semibold">FREE to join. </span>
          Easy to win.
        </div>
      </div>
      <Link
        to={{
          pathname: "/signin",
          search: referralCode,
        }}
      >
        <Button className="bg-perk-primary text-perk-primary-content hover:bg-perk-primary/90 rounded-perk-button w-full text-lg  py-3 px-6 focus:outline-none">
          Sign In
        </Button>
      </Link>
    </div>
  );
}
